import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";
import BecomeACarer from '../components/BecomeACarer';
import { CarouselComponent, CarouselComponentSlide } from '../components/carousel'
import "../../scss/main.scss";

export default function Location({ data }) {
	const {airtableLocations} = data
	const [activity1, activity2, activity3, activity4] = airtableLocations.data.activities.map(res => res.data)
	console.log([activity1, activity2, activity3, activity4])

	console.log(JSON.stringify(data, null, 4))


	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link
					rel="shortcut icon"
					type="image/svg"
					href="./assets/marketing-site/images/favicon.svg"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Locations</title>
			</Helmet>
			<div className="container">
				<Header />
				<main>
					<section>
						<div className="locations-landing">
							<div className="locations-landing-left">
								<div className="landing-content-container">
									<div className="locations-heading">
										<h1>
											Live-in care <br />
											in {airtableLocations.data.Name}
										</h1>
										<p className="primary">
											At edyn, we provide high-quality modern live-in care.
											We’re with you every step of the way.
										</p>
									</div>

									<div className="landing-left-bottom">
										<div className="trust-pilot-minimal"> </div>

										<div className="area-input">
											<div className="input-field-postcode">
												<div className="postcode-background">
													<div className="postcode-form">
														<p className="secondary">Get started with Edyn Care:</p>
														<div className="post-input-custom">
															<div>
																<input
																	className="default"
																	type="text"
																	id="postalcode"
																	name="postcode"
																	placeholder="Enter your postcode"
																/>
															</div>
															<div>
																<Link to="/locations">
																	<StaticImage
																		src="../../static/assets/marketing-site/images/searchbtn.svg"
																		placeholder="blurred"
																	/>
																</Link>
															</div>
														</div>
													</div>
												</div>
												<div className="postcode-footer">
													<div className="call-us">
														<StaticImage
															src="../../static/assets/marketing-site/images/postcodeimg1-high-res.png"
															alt="advisors-img"
															placeholder='none'
															loading='eager'
														/>
														<div className="call-us-details">
															<p className="secondary">Or call us today on:</p>
															<Link to="tel:020 3970 9900">
																<h4 className="number">020 3970 9900</h4>
															</Link>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="locations-landing-right">
								<div className="location-landing-name">
									<StaticImage
										className="icon-default"
										src="../../static/assets/marketing-site/images/location-marker.svg"
										alt="Location Marker"
										objectFit="contain"
										placeholder="blurred"
									/>
									<p>{airtableLocations.data.Splash_Image_location_description}</p>
								</div>
							</div>
						</div>
					</section>
					<hr className="mobile-hide" />

					<section>
						<div className="locations-we-understand">
							<div className="we-understand-left">
								<h2>We understand:</h2>
								<Link to="/approach" className="button-link">
									<button className="btn-primary green">
										Learn more about our approach
									</button>
								</Link>
							</div>
							<div className="we-understand-right">
								<ul>
									<li className="mobile-only">
										<h2>We understand:</h2>
									</li>
									<li>
										<StaticImage
											className="icon-default"
											src="../../static/assets/marketing-site/images/check-mark.png"
											alt="Checkmark"
											objectFit='contain'
											placeholder="blurred"
										/>
										<p className="secondary">
											Exceptional care is not an individual job
										</p>
									</li>
									<li>
										<StaticImage
											className="icon-default"
											src="../../static/assets/marketing-site/images/check-mark.png"
											alt="Checkmark"
											objectFit='contain'
											placeholder="blurred"
										/>
										<p className="secondary">Training is critical</p>
									</li>
									<li>
										<StaticImage
											className="icon-default"
											src="../../static/assets/marketing-site/images/check-mark.png"
											alt="Checkmark"
											objectFit='contain'
											placeholder="blurred"
										/>
										<p className="secondary">The importance of consistency</p>
									</li>
									<li>
										<StaticImage
											className="icon-default"
											src="../../static/assets/marketing-site/images/check-mark.png"
											alt="Checkmark"
											objectFit='contain'
											placeholder="blurred"
										/>
										<p className="secondary">
											Technology to make us more human
										</p>
									</li>
									<li className="mobile-only">
										<Link to="/approach" className="button-link">
											<button className="btn-primary green">
												Learn more about our approach
											</button>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</section>

					<section>
						<div className="locations-exceptional-care">
							<div className="ec-left">
								<h1>Providing exceptional care for the people of London</h1>
								<p className="primary">
									Recognised by industry leaders and academics as one of the
									leading providers of live-in care in {airtableLocations.data.name} and
									throughout England, Edyn has enabled hundreds of seniors to
									continue living the life they wish in their own home - happily
									and safely.
								</p>
								<p className="primary">
									If you’re concerned that your loved one is struggling to cope
									independently with day-to-day life in the {airtableLocations.data.name} area,
									then you’re probably considering your care options. Live-in
									care has become an increasingly popular option both in{" "}
									{airtableLocations.data.name} and throughout the UK. This highly
									personalised, one-to-one type of care is changing how we’re
									looking after our elderly and we’re leading the way.
								</p>
							</div>
							<div className="ec-right">
								<div className="ec-img-container ec-img-container-left">
									<StaticImage
										src="../../static/assets/marketing-site/images/ec-img-1.png"
										alt=""
										placeholder="blurred"
									/>
									<StaticImage
										src="../../static/assets/marketing-site/images/ec-img-2.png"
										alt=""
										placeholder="blurred"
									/>
								</div>
								<div className="ec-img-container ec-img-container-right">
									<StaticImage
										src="../../static/assets/marketing-site/images/ec-img-3.png"
										alt=""
										placeholder="blurred"
									/>
								</div>
							</div>
						</div>
					</section>
					<BecomeACarer className="wide" backgroundColor="$light-blue" title="Dependable carers across London" mobileTitle="Dependable carers" description="We have compassionate, positive and personable carers avaliable immediately. You have the same care team trained and supported to stay COVID-safe." cta="To find out more please call us on" contact="020 3970 9900" />
					<section>
						<div className="locations-what-expect">
							<div className="what-expect-left">
							<StaticImage
								className="locations-what-expect-background"
								src="../../static/assets/marketing-site/images/locations-pattern.svg"
								placeholder="blurred"
							/>
								<h2>What can I expect from live-in care in London</h2>
								<p className="secondary middle">
									We offer specialist support for conditions including:
								</p>
								<ul>
									<li>
										<StaticImage
											className="icon-default"
											src="../../static/assets/marketing-site/images/livein-icon-arrow.svg"
											placeholder="blurred"
										/>
										<p>Dementia</p>
									</li>
									<li>
										<StaticImage
											className="icon-default"
											src="../../static/assets/marketing-site/images/livein-icon-arrow.svg"
											placeholder="blurred"
										/>
										<p>Parkinsons</p>
									</li>
									<li>
										<StaticImage
											className="icon-default"
											src="../../static/assets/marketing-site/images/livein-icon-arrow.svg"
											placeholder="blurred"
										/>
										<p>Cancer Care</p>
									</li>
									<li>
										<StaticImage
											className="icon-default"
											src="../../static/assets/marketing-site/images/livein-icon-arrow.svg"
											placeholder="blurred"
										/>
										<p>Palliative care</p>
									</li>
								</ul>
							</div>
							<div className="what-expect-right">
								<div className="left">
									<h2>
										Live-in care enables your loved one to remain in the comfort
										of their own home, even when they have quite complex care
										needs.
									</h2>
									<p className="primary">
										The beauty is that your loved one does not need to downsize
										and give up possessions that have built up over a lifetime,
										give up a well-tended garden or loose contact with their
										local community. A professional live-in care, trained by our
										care management team, moves into your relatives home to
										provide the support they need around the clock.
									</p>
								</div>
								<div className="right">
									<p className="primary">
										Our highly personalised service is delivered by
										professionals carers who have been chosen for their mutual
										compatibility with the person they care for. We spend a lot
										of time getting to know our clients, so we can ensure the
										care they receive is tailored to their way of life.
									</p>
									<p className="primary">
										Some of our clients choose live-in care for a elderly
										relative who requires help with day-to-day tasks such as
										washing, making meals, medication, whilst others opt for 24
										hour care for a more intense level of care, such as dementia
										care.
									</p>
								</div>
							</div>
						</div>
					</section>

					<section className="local-community-mobile">
						<div className="location-local-community">
							<h2>
								We help your loved ones remain a part of the local community
							</h2>
							<div className="local-community-cards">
								<CarouselComponent>
									<CarouselComponentSlide>
										<div className="community-card community-library slide active">
											<div className="community-card-content">
												<p className="community-card-activity"> {activity1.Activity_description} </p>
												<p className="community-card-location">
													{" "}
													{activity1.Location_name}{" "}
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="community-card community-market slide">
											<div className="community-card-content">
												<p className="community-card-activity"> {activity2.Activity_description} </p>
												<p className="community-card-location">
													{" "}
													{activity2.Location_name}{" "}
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="community-card community-chess-club slide">
											<div className="community-card-content">
												<p className="community-card-activity"> {activity3.Activity_description} </p>
												<p className="community-card-location">
													{" "}
													{activity3.Location_name}{" "}
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="community-card community-clinic slide">
											<div className="community-card-content">
												<p className="community-card-activity"> {activity4.Activity_description} </p>
												<p className="community-card-location">
													{" "}
													{activity4.Location_name}{" "}
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

								</CarouselComponent>
							</div>
						</div>
					</section>

					<section className="local-community-desktop">
						<div className="location-local-community carousel">
							<h2>
								We help your loved ones remain a part of the local community
							</h2>
							<div className="local-community-cards">
								<div className="community-card community-library slide active">
									<div className="community-card-content">
										<p className="community-card-activity"> {activity1.Activity_description} </p>
										<p className="community-card-location">
											{" "}
											{activity1.Location_name}{" "}
										</p>
									</div>
								</div>
								<div className="community-card community-market slide">
									<div className="community-card-content">
										<p className="community-card-activity"> {activity2.Activity_description} </p>
										<p className="community-card-location">
											{" "}
											{activity2.Location_name}{" "}
										</p>
									</div>
								</div>
								<div className="community-card community-chess-club slide">
									<div className="community-card-content">
										<p className="community-card-activity"> {activity3.Activity_description} </p>
										<p className="community-card-location">
											{" "}
											{activity3.Location_name}{" "}
										</p>
									</div>
								</div>
								<div className="community-card community-clinic slide">
									<div className="community-card-content">
										<p className="community-card-activity"> {activity4.Activity_description} </p>
										<p className="community-card-location">
											{" "}
											{activity4.Location_name}{" "}
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="customer-say">
							<div className="customer-words">
								<p className="secondary">What our customers say:</p>
								<div className="customer-words-group">
									<StaticImage
										src="../../static/assets/marketing-site/images/quotation-mark.svg"
										objectFit='contain'
										className="quotation-mark"
										placeholder="blurred"
									/>
									<h3>
										What makes Edyn stand out from other agencies we have used
										for live-in care is the fact that they care - the human
										touch is there.”
									</h3>
								</div>
							</div>
							<div className="customer-images">
								<StaticImage
									src="../../static/assets/marketing-site/images/dementia-quote-customers.png"
									objectFit='contain'
									placeholder="blurred"
								/>
								<div className="customer-names">
									<p className="secondary">
										Sarah Maya
										<br />
										<span>Mum</span>
									</p>
									<p className="secondary">
										Julie Maya
										<br />
										<span>Daughter</span>
									</p>
								</div>
							</div>
						</div>
					</section>



					<section className="london-team-desktop">
						<div className="locations-london-team">
							<h1>Meet our local London team</h1>
							<div className="london-team">
								<div className="team-member">
									<StaticImage
										className="member-img"
										src="../../static/assets/marketing-site/images/1_london_care.png"
										alt="Gus"
										objectFit="contain"
										placeholder='tracedSVG'
									/>

									<div className="member-role">
										<p>Family Care Advisor</p>
									</div>
									<div className="member-name">
										<h3>Gus Allen</h3>
									</div>
									<div className="member-description">
										<p className="primary">
											We’re here to answer all your questions and help you
											navigate your options. Call us anytime on 020 3970 9900 or
											alternatively book a call here.
										</p>
									</div>
								</div>
								<div className="team-member">
									<StaticImage
										className="member-img"
										src="../../static/assets/marketing-site/images/2_london_care.png"
										alt="Aljona"
										objectFit="contain"
										placeholder='tracedSVG'
									/>

									<div className="member-role">
										<p>Family Care Manager</p>
									</div>
									<div className="member-name">
										<h3>Aljona Kopp</h3>
									</div>
									<div className="member-description">
										<p className="primary">
											Aljona meets all of our clients in {"{Location}"} in
											person and draws up a personalised care plan to ensure we
											tailor our service to their needs, likes and way of life.
										</p>
									</div>
								</div>
								<div className="team-member">
									<StaticImage
										className="member-img"
										src="../../static/assets/marketing-site/images/3_london_care.png"
										alt="Maria"
										objectFit="contain"
										placeholder='tracedSVG'
									/>

									<div className="member-role">
										<p>Field Care Supervisor</p>
									</div>
									<div className="member-name">
										<h3>Maria Perebzjak</h3>
									</div>
									<div className="member-description">
										<p className="primary">
											Maria works with our professional carers, district nurses,
											OTs and GPs to ensure the highest quality of care is
											provided.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					{/* CREATE NEW SECTION AND HIDE IT ON MACBOOK AND DESKTOP */}
					<section className="london-team-mobile">
						<div className="locations-london-team">
							<h1>Meet our local London team</h1>
							<div className="london-team">
								{/* ADD CAROUSEL */}
								<CarouselComponent>
									<CarouselComponentSlide>
										<div className="team-member">
										<StaticImage
											className="member-img"
											src="../../static/assets/marketing-site/images/1_london_care.png"
											alt="Gus"
											placeholder="blurred"
										/>

											<div className="member-role">
												<p>Family Care Advisor</p>
											</div>
											<div className="member-name">
												<h3>Gus Allen</h3>
											</div>
											<div className="member-description">
												<p className="primary">
													We’re here to answer all your questions and help you
													navigate your options. Call us anytime on 020 3970 9900 or
													alternatively book a call here.
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="team-member">
										<StaticImage
											className="member-img"
											src="../../static/assets/marketing-site/images/2_london_care.png"
											alt="Aljona"
											placeholder="blurred"
										/>

											<div className="member-role">
												<p>Family Care Manager</p>
											</div>
											<div className="member-name">
												<h3>Aljona Kopp</h3>
											</div>
											<div className="member-description">
												<p className="primary">
													Aljona meets all of our clients in {"{Location}"} in
													person and draws up a personalised care plan to ensure we
													tailor our service to their needs, likes and way of life.
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="team-member">
										<StaticImage
											className="member-img"
											src="../../static/assets/marketing-site/images/3_london_care.png"
											alt="Maria"
											placeholder="blurred"
										/>

											<div className="member-role">
												<p>Field Care Supervisor</p>
											</div>
											<div className="member-name">
												<h3>Maria Perebzjak</h3>
											</div>
											<div className="member-description">
												<p className="primary">
													Maria works with our professional carers, district nurses,
													OTs and GPs to ensure the highest quality of care is
													provided.
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

								</CarouselComponent>
							</div>
						</div>
					</section>

					<section>
						<div className="locations-livein-goto">
							<div className="goto-left">
								<h1>
									Why live-in care in {airtableLocations.data.name} is now the go-to option
									instead of residential care
								</h1>
							</div>
							<div className="goto-right">
								<div className="goto-reason">
									<StaticImage
										src="../../static/assets/marketing-site/images/goto-avoid-stress.png"
										alt=""
										objectFit='contain'
										placeholder="blurred"
									/>
									<div className="goto-content">
										<h3>Avoid the potential stress of relocation</h3>
										<p className="primary">
											We can understand if you're starting your care journey you
											might have been searching around every care home in
											{airtableLocations.data.name}. It’s a long and arduous process - we can
											sympathise, we’ve been there! We all want what is best for
											our family members and navigating can be hard.
										</p>
									</div>
								</div>
								<div className="goto-reason">
									<StaticImage
										src="../../static/assets/marketing-site/images/goto-maintain-control.png"
										alt=""
										objectFit='contain'
										placeholder="blurred"
									/>
									<div className="goto-content">
										<h3>Maintain control over your routine</h3>
										<p className="primary">
											Live-in care enables a fulfilling lifestyle that your
											loved one can control. They can keep doing the things they
											love, whether it’s a hobby, an activity in their own
											community or something as simple as having a friendly chat
											with an old neighbor. Maintaining this familiarity is so
											powerful.
										</p>
									</div>
								</div>
								<div className="goto-reason">
									<StaticImage
										src="../../static/assets/marketing-site/images/goto-peace-of-mind.png"
										alt=""
										objectFit='contain'
										placeholder="blurred"
									/>
									<div className="goto-content">
										<h3>Peace of mind for family and friends</h3>
										<p className="primary">
											A live-in care arrangement also benefits friends and
											family who have the peace of mind that a professional
											carer is always at hand to provide a level of care and
											assistance that is entirely commensurate with the needs of
											the individual.
										</p>
									</div>
								</div>
								<div className="goto-reason">
									<StaticImage
										src="../../static/assets/marketing-site/images/goto-personalize.png"
										alt=""
										objectFit='contain'
										placeholder="blurred"
									/>
									<div className="goto-content">
										<h3>Personalise to you</h3>
										<p className="primary">
											The beauty of our service is that it can be as flexible or
											structured as you like. We tailor it to your requests.
											Compared to a care home where things are very regimented
											they can eat the meal they want, when they want. Our
											professional carers can cook up a masterful sunday roast,
											a best of british steak or something highly nutritious.
											Our carefully matched carers get to know your loved one’s
											like they were a family member. Building relationship with
											client over time
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="location-livein-guide">
							<div className="livein-guide-left">
								<div className="sandra-quote">
									<div className="sandra-quote-1">
										<StaticImage
											src="../../static/assets/marketing-site/images/quotation-mark.svg"
											objectFit='contain'
											className="quotation-mark"
											placeholder="blurred"
										/>
										<p className="quote primary">
											Getting Mum out of a care home was made so much easier by
											the edyn team. They worked closely with the home to make
											it as smooth as possible and now Mum is back in her home
											in far better spirits. I couldn’t be happier”
										</p>
									</div>
									<div className="sandra">
										<StaticImage
											src="../../static/assets/marketing-site/images/sandra-img.png"
											alt=""
											objectFit="contain"
											placeholder="blurred"
										/>
										<div className="description">
											<p>Sandra</p>
											<p>Daughter of a client</p>
										</div>
									</div>
								</div>
							</div>
							<div className="livein-guide-right">
								<div className="form-container">
									<h1>Live-in care guide</h1>

									<div className="form-content">
										<StaticImage
											src="../../static/assets/marketing-site/images/live-in-form.png"
											objectFit="contain"
											placeholder="blurred"
										/>
										<p className="primary">
											Find out more about how live-in care might be suitable for
											your and your family with our helpful guide.
										</p>
									</div>
									<form action="">
										<div className="input-group">
											<label for="">Full name </label>
											<input className="default" type="text" />
										</div>

										<div className="input-group">
											<label for="">Mobile number </label>
											<input className="default" type="text" />
										</div>

										<div className="input-group">
											<label for="">Postcode </label>
											<input className="default" type="text" />
										</div>
										<div className="input-group">
											<Link to="">
												<button>Request a brochure</button>
											</Link>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
				</main>
			</div>
			<Footer />
		</>
	);
}

export const pageQuery = graphql`
  	query($slug: String!) {
		airtableLocations(
			data: { slug: { eq: $slug } }
		) {
			data {
				slug
				Name
				Splash_image {
					url
				}
				Splash_Image_location_description
				Image_Gallery_1 {
					url
				}
				Image_gallery_2 {
					url
				}
				Image_Gallery_3 {
					url
				}
				activities {
					data {
						Activity_description
						image {
							url
							type
						}
						Location_name
					}
				}
			}
		}
	}
`